@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Darby Sans';
    src: url('./assets/fonts/DarbySans-Regular.woff2') format('woff2'),
         url('./assets/fonts/DarbySans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  body {
    @apply font-sans;
  }
  