@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

.logo {
  height: 4em;
  display: flex;
  will-change: filter;
  transition: filter 300ms;
}
@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Montserrat', sans-serif;
  /* margin: 30px;
  margin-top: 100px; */
}

nav {
  height: 90px;
  display: flex; 
  background-color: #b2eab8;
  padding: 15px;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
}

.menu-lateral {
  position: absolute;
  top: 0;
  right: 0;
  height: 1010px;
  background-color: white;
  padding: 30px;
  padding-top: 70px;
  z-index: 3;
}

.close-menu{
  color: #0a8616;
  position: absolute;
  display: flex;
  transition: filter 300ms;
  top: 15px;
  right:15px;
  justify-content: right;
}

.opt-menu-lateral{
  justify-content: left;
  display: inline-flex;
}