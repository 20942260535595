.equipment-form {
  background-color: #ffffff;
  padding-top: 20px;
  border-radius: 8px;
  padding-left: 0px;
}

.form-row {
  display: flex;
  margin-bottom: 20px;
  gap: 100px;
  margin-left: 0px;
  padding-left: 0px;
}

.field {
  width: 100%;
}

input {
  width: 100%;
  padding: 15px;
  border: none;
  height: 50px;
  background-color: #ebebeb;
  border-radius: 20px;
  box-sizing: border-box;
  color: black;
}


.styled-select {
  position: relative;
  width: 100%;
}

.styled-select select {
  width: 100%;
  padding: 15px;
  border: none;
  height: 50px;
  background-color: #ebebeb;
  border-radius: 20px;
  box-sizing: border-box;
  color: black;
  appearance: none;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.styled-select::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
}